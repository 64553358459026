import React, { useCallback } from 'react';
import GetAppDesktop from './GetAppDesktop';
import GetAppMobile from './GetAppMobile';
import styled from 'styled-components';
import GivelifyModal from '../Donee/components/common/Modal/GivelifyModal';
import { usePushEvents } from '../../hooks/PushEvents';
import { appDownloadEvent } from '../../hooks/PushEvents/clevertapEvents';
import { isAppleDevice } from '../Donee/components/common/utils';

const breakpoint = 800;
export const isMobile = window.innerWidth < breakpoint;

const appStoreLink =
  'https://apps.apple.com/app/apple-store/id725815127?pt=8388804&ct=oga_appdownload_historymodal&mt=8';
const playStoreLink =
  'https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify&referrer=utm_source%3Doga%26utm_medium%3Dweb%26utm_campaign%3Doga_appdownload_historymodal';
export const getMobileAppLink = () => {
  // copyDoneeIdToClipboard();
  window.location.href = isAppleDevice() ? appStoreLink : playStoreLink;
};

const StyledDiv = styled.div`
  .mobile-get-app-banner {
    display: none;
  }
  @media screen and (max-width: ${breakpoint}px) {
    .mobile-get-app-banner {
      display: block;
    }
    .desktop-get-app-banner {
      display: none;
    }
  }
`;

interface GetAppModalContentProps {
  handleDownload: () => unknown;
  handleClose?: () => unknown;
}

export const GetAppModalContent: React.FC<GetAppModalContentProps> = ({
  handleClose,
  handleDownload
}) => {
  return (
    <StyledDiv>
      <GetAppMobile
        handleDownload={handleDownload}
        className={'mobile-get-app-banner'}
      />
      <GetAppDesktop
        onClose={handleClose}
        className={'desktop-get-app-banner'}
      />
    </StyledDiv>
  );
};

interface GetAppModalProps {
  open: boolean;
  setOpen: (o: boolean) => unknown;
}

const GetAppModal: React.FC<GetAppModalProps> = ({ open, setOpen }) => {
  const { pushEvent } = usePushEvents();

  const handleClose = useCallback(() => {
    document.body.classList.remove('ReactModal__Body--open');
    setOpen(false);
    pushEvent(appDownloadEvent, {
      location: 'historymodal',
      action: 'dismissed'
    });
  }, [pushEvent, setOpen]);

  const handleDownload = useCallback(() => {
    pushEvent(appDownloadEvent, {
      location: 'historymodal',
      action: 'clicked'
    });
    getMobileAppLink();
  }, [pushEvent]);

  return (
    <GivelifyModal
      maxWidth={isMobile ? undefined : '736px'}
      contentStyles={'padding: 0;'}
      open={open}
      hideHeader={!isMobile}
      onClose={handleClose}
    >
      <GetAppModalContent
        handleClose={handleClose}
        handleDownload={handleDownload}
      />
    </GivelifyModal>
  );
};

export default GetAppModal;
