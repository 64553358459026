import React from 'react';
import styled from 'styled-components';
import ImageLoader from '../ImageLoader';
import StepDots, { StepDotsProps } from './StepDots';
import { CheckoutWizardRouterProps } from '../CheckoutWizard/CheckoutWizardInterfaces';
import { Donee, useDonee } from '../../../../providers/DoneeProvider';
import { TABLET_WIDTH, getDefaultBannerImageUrl } from '../../utils';
import { webConfig } from '../../../../utils/webConfig';
import { Text } from '../common/Text/Text';
import colors from '../../../theme/colors';

const getStepNumber = (
  route: CheckoutWizardRouterProps['route']
): StepDotsProps['step'] => {
  switch (route) {
    case 'amount':
      return 1;
    case 'envelopes':
      return 2;
    default:
      return 3;
  }
};

const StyledDiv = styled.div`
  display: none;
  .cover-photo {
    width: 100%;
    height: 202px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .profile-photo {
    width: 45px;
    height: 45px;
    border-radius: 100px;
    background-size: cover !important;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
    margin-right: 16px;
  }
  .pastor-details {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 17.5px;
    padding-bottom: 17.5px;
    border-bottom: 1px solid ${colors.utilitySeparatorColor};
  }
  .step-dots {
    margin-top: 24px;
  }
  @media only screen and (max-width: ${TABLET_WIDTH}) {
    display: inherit;
  }
`;

interface StepsAndDoneeProps extends CheckoutWizardRouterProps {
  className?: string;
}

const StepsAndDonee: React.FC<StepsAndDoneeProps> = ({ route, className }) => {
  const { donee } = useDonee();
  let coverUrl = undefined;
  let profileUrl = undefined;
  let doneeType: Donee['organization']['type'] = 'church';
  let pastorName = undefined;
  let doneeName = undefined;
  let isVerified = false;
  if (donee.type === 'REQUEST_SUCCESS') {
    coverUrl = donee.data.organization.banner;
    profileUrl = donee.data.organization.official?.picture;
    doneeType = donee.data.organization.type;
    pastorName = donee.data.organization.official?.name;
    doneeName = donee.data.organization.name;
    isVerified = donee.data.organization.isPaymentVerified;
  }
  return (
    <StyledDiv className={className}>
      <ImageLoader
        url={coverUrl}
        defaultUrl={getDefaultBannerImageUrl(doneeType, isVerified)}
        className={'cover-photo'}
      />
      <div className={'pastor-details'}>
        <ImageLoader
          url={profileUrl}
          defaultUrl={webConfig.doneeAvatarDefault}
          className={'profile-photo'}
        />
        <div>
          <Text
            variant={'small'}
            color={'primarySecondaryContent'}
          >
            {pastorName}
          </Text>
          <Text
            variant={'medium'}
            weight={'light'}
          >
            {doneeName}
          </Text>
        </div>
      </div>
      <StepDots
        step={getStepNumber(route)}
        className={'step-dots'}
      />
    </StyledDiv>
  );
};

export default StepsAndDonee;
