import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { scrollbarStyles } from '../../../../Scrollbar';
import { CrossIcon } from '../../../../icons';
import { ReactChildren } from '../../../../../providers/DoneeProvider';
import { colors } from '../../../../theme';
import { BREAKPOINTS } from '../../../../theme/breakpoints';

interface GivelifyBottomSheetProps extends ReactChildren {
  initialOpenValue?: boolean;
  maxHeight?: string;
  desktopMaxWidth?: string;
  contentPadding?: string;
  className?: string;
  onClosedCallback?: () => unknown;
}

const StyledDiv = styled.div<{
  maxHeight?: string;
  contentPadding?: string;
  desktopMaxWidth?: string;
}>`
  width: 100%;
  height: 100vh;
  z-index: 999999;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
  .bottom-sheet-content {
    width: 100%;
    max-height: ${({ maxHeight }) => maxHeight};
    overflow: auto;
    padding: ${({ contentPadding }) => contentPadding};
    background-color: #ffffff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    ${scrollbarStyles}
  }
  .cancel-icon-wrapper {
    text-align: right;
    svg path {
      fill: ${colors.primarySecondaryContent};
    }
    .cancel-icon {
      cursor: pointer;
      margin-bottom: -10px;
    }
  }
  @media screen and (min-width: ${BREAKPOINTS.MOBILE}px) {
    align-items: center;
    justify-content: center;
    background: rgba(241, 240, 247, 0.8);
    .bottom-sheet-content {
      margin-left: auto;
      margin-right: auto;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      max-width: ${({ desktopMaxWidth }) => desktopMaxWidth};
      box-shadow: 0px 1px 6px 0px rgba(10, 0, 77, 0.04),
        0px 5.5px 30px 0px rgba(10, 0, 77, 0.05);
    }
  }
`;

const GivelifyBottomSheet: React.FC<GivelifyBottomSheetProps> = ({
  initialOpenValue = true,
  maxHeight = '80%',
  desktopMaxWidth = '416px',
  contentPadding = '12px 24px 24px 24px',
  children,
  className,
  onClosedCallback
}) => {
  const [show, setShow] = useState<boolean>(initialOpenValue);

  const handleClose = useCallback(() => {
    setShow(false);
    onClosedCallback?.();
  }, [setShow, onClosedCallback]);

  if (!show) return null;

  return (
    <StyledDiv
      maxHeight={maxHeight}
      contentPadding={contentPadding}
      desktopMaxWidth={desktopMaxWidth}
    >
      <div className={`bottom-sheet-content ${className}`}>
        <div className="cancel-icon-wrapper">
          <CrossIcon
            onClick={handleClose}
            className="cancel-icon"
          />
        </div>
        {children}
      </div>
    </StyledDiv>
  );
};

export default GivelifyBottomSheet;
