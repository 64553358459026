import React, { useEffect } from 'react';
import { Envelope, Envelopes } from '../../Capsules/Envelopes';
import { CheckoutWizardProps } from '../CheckoutWizardInterfaces';
import { usePushEvents } from '../../../../../hooks/PushEvents';
import { giftCauseScreen } from '../../../../../hooks/PushEvents/clevertapEvents';
import { useDonor } from '../../../../../providers/DonorProvider/useDonor';

type EnvelopesWizardProps = CheckoutWizardProps;

const EnvelopesWizard: React.FC<EnvelopesWizardProps> = ({
  cart,
  donee,
  addToCart,
  setRoute
}) => {
  const donor = useDonor();
  const { pushEvent } = usePushEvents();
  useEffect(() => {
    pushEvent(giftCauseScreen, {
      loggedIn: Boolean(donor),
      doneeId: window.doneeIdShort,
      currentSelectedAmount: cart.envelopes.new
    });
  }, [pushEvent]);
  const handleClick = (envelope: Envelope, amount: number) => {
    addToCart({ ...envelope, amount });
    setRoute('cart');
  };
  return (
    <div>
      <Envelopes
        envelopes={donee.envelopes}
        cart={cart}
        currentAmount={cart.envelopes['new']!}
        onClick={handleClick}
      />
    </div>
  );
};

export default EnvelopesWizard;
