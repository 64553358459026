import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Secure } from './Secure';
import { CreditCardNumber } from './CreditCardNumber';
import ExpiryField from './ExpiryField';
import CVVField from './CVVField';
import {
  ContentStyles,
  checkCVV,
  checkCardType,
  checkExpiryDate,
  isCardNumberValid
} from '../../utils';
import { CardThumb } from '../../../Donee/components/common/constants';
import { User } from '../../routes';
import { Text } from '../../../Donee/components/common/Text/Text';
import { ModalRouterProps } from '../../Router';
import { GivelifyButton } from '../../../Donee/components/common/Button/GivelifyButton';
import { usePushEvents } from '../../../../hooks/PushEvents';
import {
  loginPopupCardScreenNext,
  loginPopupCardScreenShown
} from '../../../../hooks/PushEvents/clevertapEvents';

const Headings: React.FC = React.memo(() => {
  return (
    <>
      <Text
        variant={'medium'}
        weight={'extraBold'}
      >
        Card Details
      </Text>
      <Secure className={'info'}>Your information is secure</Secure>
    </>
  );
});
Headings.displayName = 'Headings';

const StyledDiv = styled(ContentStyles)`
  text-align: left;
  .info {
    margin-top: 10px;
    margin-bottom: 18px;
  }
  .credit-card {
    margin-bottom: 8px;
  }
  .bottom-fields {
    display: flex;
  }
  .expiry-date {
    width: 100%;
    margin-right: 4px;
  }
  .cvv {
    width: 100%;
    margin-left: 4px;
  }
  .error-text {
    margin-top: 10px;
  }
`;
const spaceRegEx = /\s/g;

export type CreditCardProps = ModalRouterProps & {
  HeaderComponent?: React.FC;
};

export const CreditCard: React.FC<CreditCardProps> = ({
  user,
  HeaderComponent = Headings,
  onNext
}) => {
  const { pushEvent } = usePushEvents();
  // card number
  const [cardNumber, setCardNumber] = useState<string>(
    user.card?.cardNumber || ''
  );
  const [cardThumb, setCardThumb] = useState<CardThumb | undefined>(
    user.card?.cardNumber ? checkCardType(user.card?.cardNumber) : undefined
  );
  const [cardNumberError, setCardNumberError] = useState<string | undefined>();

  // expiry date
  const [expiry, setExpiry] = useState<string>(user.card?.cardExpiryDate || '');
  const [expiryError, setExpiryError] = useState<string | undefined>();

  // cvv
  const [cvv, setCvv] = useState<string>(user.card?.cardCVV || '');
  const [cvvError, setCvvError] = useState<string | undefined>();

  useEffect(() => {
    pushEvent(loginPopupCardScreenShown);
  }, []);

  // card number
  const handleCardNumberChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const cardNumber = e.target.value;
      setCardNumber(cardNumber.replace(spaceRegEx, ''));
      const thumb = checkCardType(cardNumber);
      setCardThumb(thumb);
    },
    [setCardNumber, setCardThumb]
  );

  // expiry date
  const handleExpiryChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const input = e.target.value;
      setExpiry(input);
      if (input.length === 5) {
        if (!checkExpiryDate(input)) {
          setExpiryError('*Please enter a valid expiry date');
          return;
        }
      } else if (expiryError) {
        setExpiryError(undefined);
      }
    },
    [setExpiry, setExpiryError, expiryError]
  );

  // cvv
  const handleCvvChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (value !== '' && isNaN(parseInt(value, 10))) {
        return;
      }
      setCvv(value);
    },
    [setCvv]
  );

  const handleSubmit = useCallback(() => {
    let error = false;
    if (!cardThumb || !isCardNumberValid(cardNumber, cardThumb)) {
      setCardNumberError('*Please enter a valid card number.');
      error = true;
    } else {
      setCardNumberError(undefined);
      if (!checkCVV(cvv, cardThumb)) {
        setCvvError('*Please enter a valid CVV');
        error = true;
      } else {
        setCvvError(undefined);
      }
    }
    if (!checkExpiryDate(expiry)) {
      setExpiryError('*Please enter a valid expiry date');
      error = true;
    } else {
      setExpiryError(undefined);
    }
    if (error) {
      return;
    }
    const card: User['card'] = {
      cardNumber,
      cardExpiryDate: expiry,
      cardCVV: cvv
    };
    pushEvent(loginPopupCardScreenNext);
    onNext({ ...user, card });
  }, [
    onNext,
    setCardNumberError,
    setCvvError,
    setExpiryError,
    user,
    cardThumb,
    cardNumber,
    expiry,
    cvv
  ]);

  return (
    <StyledDiv>
      <div>
        <HeaderComponent />
        <CreditCardNumber
          className={'credit-card'}
          cardNumber={cardNumber}
          cardThumb={cardThumb}
          placeholder={'Card Number'}
          cardError={cardNumberError}
          handleChange={handleCardNumberChange}
        />
        <div className={'bottom-fields'}>
          <ExpiryField
            expiry={expiry}
            expiryError={expiryError}
            handleChange={handleExpiryChange}
            placeholder={'Exp Date (mm/yy)'}
            className={'expiry-date'}
          />
          <CVVField
            className={'cvv'}
            cardThumb={cardThumb}
            cvv={cvv}
            handleChange={handleCvvChange}
            cvvError={cvvError}
          />
        </div>
        {user.skipsSignup && (
          <Text
            variant={'xSmall'}
            color={'red'}
            className={'error-text'}
          >
            We were unable to validate your card. Please ensure that you are
            entering all the details correctly.
          </Text>
        )}
      </div>
      <div className={'continue'}>
        <GivelifyButton
          onClick={handleSubmit}
          // disabled={!isValidCard(cardNumber, expiry, cvv, cardThumb)}
        >
          Next
        </GivelifyButton>
      </div>
    </StyledDiv>
  );
};
