import { ROUTES } from '../../../../utils/routes';
import { Cart, SelectedEnvelope } from '../Capsules/Envelopes';

export type DoneeRoutes =
  | 'login'
  | 'donation'
  | 'profile'
  | 'edit-profile'
  | 'history'
  | 'wallet'
  | 'recurring'
  | 'support';

export const getDoneeRoute = (path: string): DoneeRoutes => {
  switch (path) {
    case ROUTES.profile():
      return 'profile';
    case ROUTES.history:
      return 'history';
    case ROUTES.wallet:
      return 'wallet';
    case ROUTES.recurring:
      return 'recurring';
    case ROUTES.support:
      return 'support';
    default:
      return 'donation';
  }
};

export const getTotalCartAmount = (
  cart: Cart
): { totalAmount: number; donations: SelectedEnvelope[] } => {
  const values: (SelectedEnvelope | number | undefined)[] = Object.values(
    cart.envelopes
  );
  let totalAmount = 0;
  const donations: SelectedEnvelope[] = [];
  values.forEach((v) => {
    if (v && typeof v !== 'number') {
      totalAmount += v.amount;
      donations.push(v);
    }
  });
  return { totalAmount, donations };
};
