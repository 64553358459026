import React, { ChangeEvent } from 'react';
import TextInput from '../../Donee/components/common/Input/TextInput';
import { Text } from '../../Donee/components/common/Text/Text';
import { icons } from '../../Donee/components/common/Icon/constants';

interface TextfieldProps {
  id?: string;
  autoFocus?: boolean;
  type?: 'text' | 'email' | 'password' | 'number';
  value?: string | number;
  icon?: keyof typeof icons;
  onIconClick?: () => unknown;
  iconClassName?: string;
  defaultValue?: string | number;
  placeholder: string;
  maxLength: number;
  inputRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  errorText: string | undefined;
  onMouseLeave?: () => unknown;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => unknown;
}

export const Textfield: React.FC<TextfieldProps> = ({
  id,
  autoFocus,
  type = 'text',
  value,
  defaultValue,
  placeholder,
  maxLength,
  inputRef,
  className,
  errorText,
  icon,
  iconClassName,
  onIconClick,
  onChange,
  onMouseLeave
}) => {
  return (
    <div className={className}>
      <TextInput
        id={id}
        autoFocus={autoFocus}
        type={type}
        value={value}
        icon={icon}
        iconClassName={iconClassName}
        onIconClick={onIconClick}
        inputRef={inputRef}
        maxLength={maxLength}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        onMouseLeave={onMouseLeave}
      />
      {errorText && (
        <Text
          variant={'xSmall'}
          color={'red'}
        >
          {errorText}
        </Text>
      )}
    </div>
  );
};
