import applyCaseMiddleware from 'axios-case-converter';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';
import { webConfig } from './webConfig';
import {
  getAccessTokenFromLocalStorage,
  getRefreshTokenFromLocalStorage,
  setLoginToken
} from '../providers/DonorProvider/donorLocalStorage';
import { logError } from './logError';
import { LoginResponse } from '../providers/DonorProvider/interfaces';
import { GivingApiResponse } from './makeApiRequest';
import { pushEvent } from '../hooks/PushEvents/clevertap';

export type ById<T = any> = { [id: number]: T | undefined };
export const userAgent = window.navigator.userAgent;
export const appName = window.navigator.appName || userAgent;

const getAccessToken = (): string | undefined => {
  const accessToken = window.accessToken || getAccessTokenFromLocalStorage();
  return accessToken && accessToken !== 'undefined'
    ? `Bearer ${accessToken}`
    : undefined;
};

export const axiosClient = applyCaseMiddleware(
  axios.create({
    baseURL: webConfig.baseAPIUrl
  })
);

const headerCamelCaseMiddleFunction = (
  config: AxiosRequestConfig
):
  | InternalAxiosRequestConfig<any>
  | Promise<InternalAxiosRequestConfig<any>> => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization:
      config.headers?.['clientAccessToken'] ||
      config.headers?.['Authorization'] ||
      getAccessToken(),
    'Device-Name': config.headers?.['Device-Name'] || appName,
    'App-Install-Id': config.headers?.['App-Install-Id'] || userAgent,
    'App-Version': webConfig.appVersion
  } as any
});

interface AxiosRequestConfigExtended extends AxiosRequestConfig {
  _retry?: boolean;
  headers: any;
}

const refreshTokenFailed = (
  originalRequest: AxiosRequestConfigExtended,
  err?: Error,
  event?: string
) => {
  // window.accessToken = undefined;
  // deleteAccessTokens();
  const refreshToken = getRefreshTokenFromLocalStorage();
  const eventName = `Unable to refresh the token in '${event}'.`;
  logError(eventName, {
    event,
    exception: err,
    originalRequest,
    refreshToken
  });
  // window.location.href = `/donate/${ROUTES.login}&r=${window.location.pathname}`;
};

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfigExtended;
    if (
      originalRequest &&
      error.response?.status === 401 &&
      !originalRequest._retry
    ) {
      const token = getRefreshTokenFromLocalStorage();
      if (!token) return;
      return axiosClient
        .post(
          webConfig.refreshTokenBaseUrl,
          {
            refreshToken: token
          },
          {
            headers: {
              Authorization: '-'
            }
          }
        )
        .then((response: AxiosResponse<GivingApiResponse<LoginResponse>>) => {
          const accessToken = response.data.response.payload.accessToken;
          if (response.status === 401 || response.data.response.code === 401)
            return;
          if (!accessToken) {
            refreshTokenFailed(
              originalRequest,
              undefined,
              'No access token found.'
            );
            return;
          }
          originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
          setLoginToken(response.data.response.payload);
          return axiosClient(originalRequest);
        })
        .catch((err: Error) => {
          refreshTokenFailed(
            originalRequest,
            err,
            'Exception occurred when refreshing.'
          );
        });
    }
    originalRequest._retry = true;
    return Promise.reject(error);
  }
);

axiosClient.interceptors.request.use(headerCamelCaseMiddleFunction);
