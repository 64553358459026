import React, { ChangeEvent } from 'react';
import { Textfield } from '../Textfield';
import { CardThumb, cards } from '../../../Donee/components/common/constants';

interface CVVFieldProps {
  className?: string;
  cvv: string;
  cardThumb: CardThumb | undefined;
  cvvError?: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => unknown;
}

const CVVField: React.FC<CVVFieldProps> = ({
  className,
  cvvError,
  cardThumb = cards[0],
  handleChange,
  cvv
}) => {
  return (
    <Textfield
      id={'cvv'}
      placeholder={'CVV'}
      value={cvv}
      // onMouseLeave={() => onHover(email)}
      maxLength={cardThumb.cvcLength[0]}
      className={className}
      errorText={cvvError}
      onChange={handleChange}
    />
  );
};

export default React.memo(CVVField);
