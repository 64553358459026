import React from 'react';
import {
  Input as StyledInput,
  InputIcon as StyledInputIcon,
  InputIconContainer as StyledInputIconContainer,
  InputContainer as StyledInputContainer
} from './styles';
import { icons } from '../../Icon/constants';

interface InputProps {
  id?: string;
  name?: string;
  required?: boolean;
  autoFocus?: boolean;
  iconClassName?: string;
  placeholder?: string;
  type?: string;
  minLength?: number;
  maxLength?: number;
  onBlur?: () => unknown;
  onIconClick?: () => unknown;
  onMouseLeave?: () => unknown;
  onChange?: (e: any) => unknown;
  icon?: keyof typeof icons;
  iconActive?: keyof typeof icons;
  value?: string | number;
  defaultValue?: string | number;
  inputRef?: any;
  disabled?: boolean;
}

class Input extends React.Component<InputProps> {
  state = {
    focused: false
  };

  onInputBlur = () => this.setState(() => ({ focused: false }));
  onInputFocus = () => this.setState(() => ({ focused: true }));

  render() {
    const { focused } = this.state;
    const {
      value,
      icon,
      iconActive,
      inputRef,
      onIconClick,
      iconClassName,
      ...props
    } = this.props;

    return (
      <StyledInputContainer>
        {icon && (
          <StyledInputIconContainer
            onClick={onIconClick}
            className={iconClassName}
          >
            <StyledInputIcon
              size={'xSmall'}
              name={focused && iconActive ? iconActive : icon}
            />
          </StyledInputIconContainer>
        )}
        <StyledInput
          ref={inputRef}
          value={value}
          onBlur={this.onInputBlur}
          onFocus={this.onInputFocus}
          {...props}
        />
      </StyledInputContainer>
    );
  }
}

export default Input;
