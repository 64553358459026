import React from 'react';
import styled from 'styled-components';
import CardLabel from '../Payment/CardLabel';
import { CardNumbers } from '../Payment/CardInfo';
import colors from '../../../../../theme/colors';
import { Text } from '../../../common/Text/Text';
import { SelectedCheckmark } from '../../../../../icons';
import { Wallet } from '../../../../../../providers/DonorProvider/interfaces';

interface PaymentCardProps {
  card: Wallet;
  isSelected: boolean;
  onClick: (card: Wallet) => unknown;
}

const StyledButton = styled.button<{ isSelected: boolean }>`
  padding: 0;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 24px;
  background-color: inherit;
  border: 2px solid;
  border-color: ${({ isSelected }) =>
    isSelected ? colors.primaryGuidingViolet : 'transparent'};
  box-shadow: 0px 1px 6px rgba(10, 0, 77, 0.04),
    0px 5.5px 30px rgba(10, 0, 77, 0.05);
  & * {
    cursor: pointer;
  }
  .card-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 16px 12px 16px;
    border-bottom: 1px solid ${colors.utilitySeparatorColor};
    .card-type {
      width: 76px;
      height: 50px;
    }
    .card-type-text {
      margin-left: 10px;
      margin-right: 6px;
    }
  }
  .card-select {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .card-select-text {
      margin-top: 2px;
      margin-left: 10px;
    }
  }
`;

const PaymentCard: React.FC<PaymentCardProps> = ({
  card,
  isSelected,
  onClick
}) => {
  const handleClick = () => onClick(card);
  return (
    <StyledButton
      isSelected={isSelected}
      onClick={handleClick}
    >
      <div className={'card-details'}>
        <CardLabel
          cardType={card.type}
          className={'card-type'}
        />
        <Text className={'card-type-text'}>{card.type}</Text>
        <CardNumbers card={card} />
      </div>
      <div className={'card-select'}>
        {isSelected && <SelectedCheckmark />}
        <Text
          color={'primaryGuidingViolet'}
          className={'card-select-text'}
        >
          {isSelected ? 'Selected card' : 'Choose this card'}
        </Text>
      </div>
    </StyledButton>
  );
};

export default PaymentCard;
