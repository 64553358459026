import { styled } from 'styled-components';
import { assetUrl } from '../../utils/webConfig';

export { default as colors } from './colors';
export { default as width } from './width';
export { default as fonts } from './fonts';
export { default as layout } from './layout';
export { default as gradients } from './gradients';
export { default as shadows } from './shadows';

export const COMPONENT_VALUES = {
  box: `
    overflow: hidden;
    border-radius: 31px;
    transition: 0.5s all ease-in-out;
    box-shadow: 0px 1px 6px rgba(10, 0, 77, 0.04), 0px 5.5px 30px rgba(10, 0, 77, 0.05);
  `
};

export const SelectInput = styled.select`
  border: 1px solid #b1bac4;
  background: url(${assetUrl('/images/svg/select_arrow.svg')}) no-repeat scroll
    right 15px;
  background-color: #fff !important;
  color: #3a4c63;
  padding: 11px 15px;
  width: 100%;
  height: 45px;
  font-size: 16px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
`;
