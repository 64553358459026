import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ReceiptCard from './ReceiptCard';
import { Donee } from '../../../../../providers/DoneeProvider';
import { RequestState } from '../../../../../hooks/useRequestState';
import { GivingApiResponse } from '../../../../../utils/makeApiRequest';
import { ReceiptResponse } from '../CartWizard/Payment/api';
import { Text } from '../../common/Text/Text';
import { isAppleDevice } from '../../common/utils';
import { GivelifyButton } from '../../common/Button/GivelifyButton';
import GivelifyBottomSheet from '../../common/Modal/GivelifyBottomSheet';
import { usePushEvents } from '../../../../../hooks/PushEvents';
import { appDownloadEvent } from '../../../../../hooks/PushEvents/clevertapEvents';
import { DonorContextProps } from '../../../../../providers/DonorProvider/interfaces';

const appStoreLink =
  'https://apps.apple.com/app/apple-store/id725815127?pt=8388804&ct=oga_appdownload_receiptbottomsheet&mt=8';
const playStoreLink =
  'https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify&referrer=utm_source%3Doga%26utm_medium%3Dweb%26utm_campaign%3Doga_appdownload_receiptbottomsheet';

const handleDownloadButton = () => {
  // copyDoneeIdToClipboard();
  window.location.href = isAppleDevice() ? appStoreLink : playStoreLink;
};

interface ReceiptModalProps {
  donee: Donee;
  isReceiptBlur: boolean;
  donorContext: DonorContextProps;
  requestState: RequestState<GivingApiResponse<ReceiptResponse, number>>;
}

const StyledDiv = styled.div`
  .receipt-modal-heading {
    text-align: center;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }
  .receipt-card {
    margin-top: 16px;
    margin-bottom: 24px;
    filter: drop-shadow(0px 1px 2px #ddd);
  }
`;

const showThis = window.innerWidth < 501;
const ReceiptModal: React.FC<ReceiptModalProps> = ({
  donee,
  donorContext,
  isReceiptBlur,
  requestState
}) => {
  const { pushEvent } = usePushEvents();
  const [show, setShow] = useState<boolean>(showThis);

  useEffect(() => {
    if (show) {
      pushEvent(appDownloadEvent, {
        location: 'receiptbottomsheet',
        action: 'shown'
      });
    }
  }, [pushEvent]);

  const handleDownloadClicked = useCallback(() => {
    pushEvent(appDownloadEvent, {
      location: 'receiptbottomsheet',
      action: 'clicked'
    });
    handleDownloadButton();
  }, [pushEvent]);

  const handleClose = useCallback(() => {
    setShow(false);
    pushEvent(appDownloadEvent, {
      location: 'receiptbottomsheet',
      action: 'dismissed'
    });
  }, [setShow]);

  if (!show) return null;

  return (
    <GivelifyBottomSheet initialOpenValue={window.innerWidth < 501}>
      <StyledDiv>
        <Text
          variant="large"
          className="receipt-modal-heading"
        >
          Get the free Givelify App to view your full receipt
        </Text>
        <ReceiptCard
          donee={donee}
          isReceiptBlur={isReceiptBlur}
          donorContext={donorContext}
          requestState={requestState}
          className="receipt-card"
        />
        <GivelifyButton
          color="primary"
          onClick={handleDownloadClicked}
        >
          Get the Givelify App
        </GivelifyButton>
      </StyledDiv>
    </GivelifyBottomSheet>
  );
};

export default ReceiptModal;
